// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        :loading="loading"
        layout="vertical"
        @finish="handleSubmit">
        <a-form-item name="username" ref="username">
          <template #label>
            <tooltip-label :title="$t('label.account.name.of')" :tooltip="$t('label.account.name.of.tooltip')"/>
          </template>
          <a-input
            v-model:value="form.username"
            :placeholder="apiParams.username.description"
            v-focus="true" />
        </a-form-item>
        <!-- WP Implementation -->
        <a-form-item ref="invoiceName" name="invoiceName">
          <template #label>
            <tooltip-label :title="$t('label.company.name')" :tooltip="$t('label.company.name.tooltip')"/>
          </template>
          <a-input v-model:value="form.invoiceName" :placeholder="'Company name that appears on the invoice'" />
        </a-form-item>
        <!-- Customer NR -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="customerID" name="customerID">
              <template #label>
                <tooltip-label :title="$t('label.customernumber')" :tooltip="$t('label.account.customerID.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.customerID"
                :placeholder="$t('label.customernumber')" />
            </a-form-item>
          </a-col>
          <!-- ContractNumber -->
          <a-col :md="24" :lg="12">
            <a-form-item ref="contractNumber" name="contractNumber">
              <template #label>
                <tooltip-label :title="$t('label.contractnumber')" :tooltip="$t('label.account.contractNumber.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.contractNumber"
                :placeholder="$t('label.contractnumber')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- Street + Nr -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="16">
            <a-form-item ref="street" name="street">
              <template #label>
                <tooltip-label :title="$t('label.streetname.nr')" :tooltip="$t('label.account.street.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.street"
                :placeholder="$t('label.streetname.nr')"/>
            </a-form-item>
          </a-col>
          <!-- Postal Code -->
          <a-col :md="24" :lg="8">
            <a-form-item ref="postalcode" name="postalcode">
              <template #label>
                <tooltip-label :title="$t('label.postalcode')" :tooltip="$t('label.account.postalcode.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.postalcode"
                :placeholder="$t('label.postalcode')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- City -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="city" name="city">
              <template #label>
                <tooltip-label :title="$t('label.city')" :tooltip="$t('label.account.city.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.city"
                :placeholder="$t('label.city')"/>
            </a-form-item>
          </a-col>
          <!-- Country -->
          <a-col :md="24" :lg="12">
            <a-form-item ref="country" name="country">
              <template #label>
                <tooltip-label :title="$t('label.country')" :tooltip="$t('label.account.country.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.country"
                :placeholder="$t('label.country')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { timeZone } from '@/utils/timezone'
import debounce from 'lodash/debounce'
import TooltipLabel from '@/components/widgets/TooltipLabel'
// WP Implementation
import wpapi from '@/wpApi/myApi'
// /WP Implementation

export default {
  name: 'EditAccount',
  components: {
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    currentAction: {
      type: Object,
      required: true
    }
  },
  data () {
    this.fetchTimeZone = debounce(this.fetchTimeZone, 800)
    return {
      loading: false,
      timeZoneLoading: false,
      timeZoneMap: [],
      accountUUID: null,
      domainUUID: null,
      accountName: null,
      // WP Implementation
      wpAccountData: [],
      customerIdRegex: /^[0-9_-]*$/, // only digits, -_
      contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, // only letters, digits, -_.
      cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
      specialChars: /[`´!@#$%^&*()+=[\]{};':"\\|,<>/?ß~]/
      // /WP Implementation
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('updateUser')
  },
  async created () {
    this.initForm()
    await this.fetchData()
  },
  methods: {
    // WP Implementation
    async fetchWpAccountData (accID) {
      await api2.GetAccountDetails(accID).then(response => {
        this.wpAccountData = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    initForm () {
      this.formRef = ref()
      this.form = reactive({
      })
      this.rules = reactive({
        invoiceName: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCompanyName }
        ],
        customerID: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCustomerID }
        ],
        contractNumber: [
          { required: false, message: '' },
          { validator: this.validateContractNumber }
        ],
        country: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCountry }
        ],
        street: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateStreet }
        ],
        postalcode: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validatePlz }
        ],
        city: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCity }
        ],
        username: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateAccountName }
        ]
      })
    },
    validateAccountName (rule, value) {
      var errorMessage = this.$t('message.input.account.username')
      var validated = true
      if (this.specialChars.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.special')
      }
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += this.$t('message.input.account.length')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateCompanyName (rule, value) {
      // const errorMessage = this.$t('message.validate.special.characters')
      var errorMessage = this.$t('message.input.account.firmname')
      var validated = true
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += this.$t('message.input.account.firmname.length')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateCustomerID (rule, value) {
      const errorMessage = this.$t('message.input.account.customerid')
      if (value !== undefined && this.customerIdRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateContractNumber (rule, value) {
      const errorMessage = this.$t('message.input.account.contractnumber')
      if (this.contractNumberRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateStreet (rule, value) {
      const errorMessage = this.$t('message.input.account.address')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validatePlz (rule, value) {
      const errorMessage = this.$t('message.input.account.postalcode')
      if (value !== undefined && value.length <= 8) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCity (rule, value) {
      const errorMessage = this.$t('message.input.account.city')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCountry (rule, value) {
      const errorMessage = this.$t('message.input.account.country')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchData () {
      this.accountUUID = this.resource.id
      this.domainUUID = this.resource.domainid
      this.accountName = this.resource.name
      this.fetchTimeZone()
      await this.fetchWpAccountData(this.resource.id)
      this.fillEditFormFieldValues()
    },
    // /WP Implementation
    fetchTimeZone (value) {
      this.timeZoneMap = []
      this.timeZoneLoading = true

      timeZone(value).then(json => {
        this.timeZoneMap = json
        this.timeZoneLoading = false
      })
    },
    fillEditFormFieldValues () {
      const form = this.form
      this.loading = true
      form.customerID = this.wpAccountData.customernumber
      form.contractNumber = this.wpAccountData.contractnumber
      form.country = this.wpAccountData.country
      form.street = this.wpAccountData.address
      form.postalcode = this.wpAccountData.plz
      form.city = this.wpAccountData.city
      form.username = this.accountName
      form.invoiceName = this.wpAccountData.customername
      this.loading = false
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        this.loading = true
        const params = {
          id: this.accountUUID,
          domainid: this.domainUUID,
          newname: values.username
        }
        var wpParams = new FormData()
        wpParams.append('accountUUID', this.resource.id)
        wpParams.append('country', values.country)
        wpParams.append('customer', values.customerID)
        wpParams.append('contractNumber', values.contractNumber)
        wpParams.append('customerName', values.invoiceName)
        wpParams.append('plz', values.postalcode)
        wpParams.append('streetName', values.street)
        wpParams.append('city', values.city)
        wpParams.append('shortName', values.account)

        wpapi.updateAccountWpData(wpParams).then(response => {
          api('updateAccount', params).then(response => {
            console.log('WP Account Data Updated!')
          }).catch(error => {
            this.$notification.error({
              message: this.$t('message.request.failed'),
              description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
              duration: 0
            })
          })
          this.$emit('refresh-data')
          this.$notification.success({
            message: this.$t('label.edit.user'),
            description: `${this.$t('message.success.update.user')} ${params.username}`
          })
          this.closeAction()
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
.form-layout {
  width: 80vw;
  @media (min-width: 600px) {
    width: 450px;
  }
}
</style>
